<template>
	<div class="common-layout">
		<el-container class="layout-container-demo" :style="['height:'+content_height+'px']">
			<el-aside   :width="isCollapse==false ? '160px' : '55px'">
				<div class="logo">
					<img  src="../../assets/logo.png" class="logo_img" /> 
					<div class="logo_title">logo</div>
				</div>
				
				<el-menu :default-openeds="['1']" :collapse-transition="false" 
				:collapse="isCollapse"  class="el-menu-vertical-demo" active-text-color=""
				 text-color="white"
				 @select="handleSelect" background-color="black">
				<el-menu-item index="census">
					<el-icon style="position:relative;top:-3px;" color="#ffffff"><DataAnalysis /></el-icon>
					<template #title>
						  <!-- <el-icon style="position:relative;top:-3px;" color="#ffffff"><DataAnalysis /></el-icon> -->
						  <span> 统计</span>
					</template>
				</el-menu-item>
					<el-submenu index="2">
						
						<template #title>
							<!-- <el-icon style="position:relative;top:-3px;" color="#ffffff"><IconMenu /></el-icon> -->
							<el-icon  color="#ffffff"><IconMenu /></el-icon>
							<span> 商品订单</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="goods_img"><span style="font-size: 12px;">商品图片</span></el-menu-item>
							<el-menu-item index="classify"><span style="font-size: 12px;">商品分类</span></el-menu-item>
							<el-menu-item index="goods"><span style="font-size: 12px;">商品</span></el-menu-item>
							<el-menu-item index="order"><span style="font-size: 12px;">订单</span></el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					
					<el-menu-item index="user">
						<el-icon style="position:relative;top:-3px;" color="#ffffff"><User /></el-icon>
						<template #title>
							<!-- <el-icon style="position:relative;top:-3px;" color="#ffffff"><User /></el-icon> -->
							<span> 用户</span>
						</template>
					</el-menu-item>
					
					<!-- <el-submenu index="3">
						<template #title>
							<el-icon style="position:relative;top:-3px;"><icon-menu /></el-icon> 统计
						</template>
						<el-menu-item-group>
							<el-menu-item index="census"><span style="font-size: 12px;">订单统计</span></el-menu-item>
						</el-menu-item-group>
					</el-submenu> -->
					<!-- <el-submenu index="3">
						<template #title>
							<el-icon style="position:relative;top:-3px;"><setting /></el-icon> 设置
						</template>
						<el-menu-item-group>
							<el-menu-item index="3-1">小程序设置</el-menu-item>
						</el-menu-item-group>
					</el-submenu> -->
		 		</el-menu>
			</el-aside>
			<el-container>
				<el-header style="font-size: 12px;background: #f4f4f4;" class="header">
					<div class="left" @click="isCollapse=!isCollapse">
						<el-icon style="position:relative;top:2px;"><Fold /></el-icon>
						<span class="text">您好</span>
					</div>
					<div class="right">
						<el-dropdown>
							<el-icon style="margin-right: 8px; margin-top: 1px"><setting /></el-icon>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item>View</el-dropdown-item>
									<el-dropdown-item>Add</el-dropdown-item>
								<el-dropdown-item>Delete</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
						<span>Tom</span>
					</div>
				</el-header>	

				<el-main :style="['height:'+content_height+'px']">
					<el-scrollbar class="zdy_srollbar">
						<router-view />
					</el-scrollbar>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script  setup>
// import { ref, onMounted } from 'vue';
// onMounted(() => {
// 	
// 	console.log(header.clientHeight);
// });

import { ref,onMounted } from 'vue'
import {useRouter} from 'vue-router'
import { Menu as IconMenu, Message, Setting,User } from '@element-plus/icons-vue'
const content_height = ref(0);
content_height.value = document.documentElement.clientHeight;
onMounted(()=>{
	handleSelect('census');
})
const item = {
  date: '2016-05-02',
  name: 'Tom',
  address: 'No. 189, Grove St, Los Angeles',
}
const tableData = ref(Array.from({ length: 20 }).fill(item))
const this_key=ref(null);
const router=useRouter();
const isCollapse=ref(false);
const handleSelect=(key, keyPath,el)=>{
	if(this_key.value==key){
		return 
	}
	this_key.value=key
	router.push('/'+key)
	// this.$router.push({name:this_key,params:{title:el.$el.innerText}})
}
</script>
<style>
.el-menu-item-group__title{
	padding: 0px !important;
}

/* .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 80px;
  min-height: 400px;
} */

</style>
<style scoped lang="scss">
.header{
	height: 50px;line-height: 50px;display: flex;
	.left{
		text-align: left;width: 50%;
		.icon{
			vertical-align:-22%;font-size: 18px;
		}
		.text{
			color: #667171;margin-left: 5px;
		}
	}
	.right{
		text-align: right;width: 50%;
	}
}
.logo{
	padding: 20px 15px;display: flex;
}
.logo_img{
	width: 30px;height: 30px;
}
.logo_title{
	font-size: 15px;margin-left: 10px;line-height: 30px;height: 30px;font-weight: 700;color: white;
}
.zdy_srollbar{
	background: #fafafa;
	padding: 10px;
}
.layout-container-demo .el-header {
  position: relative;
  background-color: white;
  color: var(--el-text-color-primary);
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: black;
  font-size: 10px;
}

.el-submenu .el-menu-item{
	background: black;color: white;
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}


</style>
